import { mapGetters } from "vuex";

export const mapHelper = {
  ...mapGetters([
    "getProducts",
    "getItemInStore",
    "getTotalGlobal",
    "getOrder",
    "getOperator",
    "getReference",
    "getOptReference",
    "getRoles",
    "getEntities",
    "getListUsers",
      "getEditItemList"
  ]),
};
