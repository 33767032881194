import axios from "axios";
import router from "../src/router";
const apiClient = axios.create({
  baseURL: "https://gr.netpoint.ao/api/public",
  //baseURL: "https://gr.netpoint.ao/api/public",
  withCredentials: false,
  validateStatus: () => true,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json"
  }
});

const clientFormData = axios.create({
  baseURL: "https://gr.netpoint.ao/api/public",
  withCredentials: false,
  responseType: "blob",
  headers: {
    Accept: "Application/json"
    // "Content-Type": "multipart/form-data",
  }
});

const apiClientCSV = axios.create({
  baseURL: "https://gr.netpoint.ao/api/public",
  withCredentials: false,
  validateStatus: () => true,
  responseType: "arraybuffer",
  headers: {
    Accept: "Application/json"
  }
});

export default {
  customizeHeader(token) {
    clientFormData.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    apiClientCSV.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    return (apiClient.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${token}`);
  },
  interceptionRoute() {
    return apiClient.interceptors.response.use(
      response => response,
      error => {
        if (error.response.status === 401) {
          return router.push({ name: "Login" }).catch.catch(() => {
            throw new Error("Problem - Contact Kyami Pay");
          });
        }
        return Promise.reject(error);
      }
    );
  },

  /**
   *
   * Global Statement
   *
   */

  async getLogin(cred) {
    return await apiClient.post("/api/login", cred);
  },

  async getLogout(cred) {
    return await apiClient.post("/api/login", cred);
  },

  async getProducts() {
    return await apiClient.get("/api/product");
  },

  async callDeleteProduct(id) {
    return await apiClient.delete("/api/product/" + id.id);
  },


    async callEditProduct(id) {
        return await apiClient.put("/api/product/" + id.refId, id);
    },
  async callCreateInvoice(cred) {
    return await apiClient.post("/api/invoice", cred);
  },

  async callCreateReference(cred) {
    return await apiClient.post("/api/create/reference", cred);
  },

  async callCreateBulkReference(cred) {
    return await apiClientCSV.post("/api/create/bulk/reference", cred);
  },

  async registerProduct(cred) {
    return await apiClient.post("/api/product", cred);
  },
  async consultReference(cred) {
    return await apiClient.post("/api/consult/payment", cred);
  },
  async callCreateUser(cred) {
    return await apiClient.post("/api/user", cred);
  },

  async callCreateEntity(cred) {
    return await apiClient.post("/api/entity/create", cred);
  },

  async callGetRole() {
    return await apiClient.get("/api/user/role");
  },

  async callEntity() {
    return await apiClient.get("/api/entity/get");
  },

  async callAllUsers() {
    return await apiClient.get("/api/user/get/all");
  },

  async callDeleteUser(cred) {
    return await apiClient.delete("/api/user/" + cred.id);
  }

  /**
   * Old request
   * @param cred
   */
};
