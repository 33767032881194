<template>
  <v-overlay :value="overlay" class="hidden-print">
    <v-progress-circular
      value="5"
      indeterminate
      rotate="100"
      size="100"
      width="2"
    ></v-progress-circular>
    <span style="background-color: #2d2b2b"></span>
  </v-overlay>
</template>

<script>
import eventBus from "../../main";

export default {
  name: "overlay",
  data: () => ({
    overlay: false,
  }),
  created() {
    const self = this;
    eventBus.$on("openOverlay", function (payload) {
      self.overlay = payload;
    });
  },
};
</script>

<style scoped>
@media print {
  .hidden-print,
  .hidden-print * {
    display: none !important;
  }
}
</style>
