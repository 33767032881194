<template>
  <v-app>
    <nav-bar></nav-bar>
    <v-main>
      <!-- Provides the application the proper gutter -->
      <v-container fluid>
        <!-- If using vue-router -->
        <v-fade-transition mode="out-in">
          <router-view />
          <dashboard></dashboard>
        </v-fade-transition>
      </v-container>
    </v-main>

    <v-footer app class="hidden-print">
      <span class="m-size-font-10 text-uppercase"
        >Aplicação de controlo e geração de referências</span
      >
      <v-spacer></v-spacer>
    </v-footer>
  </v-app>
</template>

<script>
import NavBar from "../../components/bar/NavBar";
import Dashboard from "../../components/home/Dashboard";
export default {
  name: "Home",
  components: {Dashboard, NavBar },
  methods: {

  },
};
</script>

<style scoped></style>
