<template>
  <div>
    <v-card elevation="0" width="600" class="mx-auto m-txt-center mt-12">
      <v-card-text class="text-uppercase mt-12 m-size-font-14 font-weight-bold"
        >Utilizadores</v-card-text
      >
    </v-card>

    <v-row>
      <v-card elevation="0" width="700" class="mx-auto m-txt-center">
        <div class="mt-6 text-left">
          <v-btn :to="{ name: 'home' }" depressed color="success" class="mx-2">
            Voltar
          </v-btn>
        </div>
        <v-sheet color="transparent" min-height="50" elevation="0">
          <v-card-title>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Filtrar resultados"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <!--          @click:row="showSingleReport"-->
          <v-data-table
            :headers="headers"
            :items="getListUsers"
            :sort-desc="true"
            :disable-items-per-page="false"
            multi-sort
            class="elevation-0"
            :items-per-page-options="[5, 10, 15]"
            :search="search"
            :items-per-page="6"
          >
            <template v-slot:item.opcoes="{ item }">
              <!--              <v-btn-->
              <!--                class="ma-0"-->
              <!--                fab-->
              <!--                color="primary"-->
              <!--                x-small-->
              <!--                @click="openDisease(item.id)"-->
              <!--              >-->
              <!--                <v-icon>mdi-pencil</v-icon>-->
              <!--              </v-btn>-->

              <v-btn class="ma-2" fab color="red" x-small>
                <v-icon
                  class="text--white"
                  style="color: white !important"
                  @click="openDisease(item.id)"
                  >mdi-delete</v-icon
                >
              </v-btn>
            </template>
          </v-data-table>
          <!--          <div-->
          <!--            style="-->
          <!--              border-bottom: 1px dashed #f6f6f6;-->
          <!--              padding: 10px;-->
          <!--              background-color: #ffffff;-->
          <!--            "-->
          <!--          ></div>-->
          <!--          <div-->
          <!--            style="-->
          <!--              border-bottom: 1px dashed #f6f6f6;-->
          <!--              padding: 10px;-->
          <!--              background-color: #ffffff;-->
          <!--            "-->
          <!--          ></div>-->
          <!--          <div style="padding-top: 100px"></div>-->
        </v-sheet>
      </v-card>
    </v-row>

    <overlay></overlay>
  </div>
</template>

<script>
import { mapHelper } from "../../store/helper";
import overlay from "../helpers/overlay";

export default {
  name: "ListUser",
  comments: { overlay },
  data: () => ({
    search: "",
    headers: [
      {
        text: "PRIMEIRO NOME",
        align: "start",
        sortable: true,
        value: "first_name"
      },
      { text: "ÚLTIMO NOME", value: "last_name", align: "start" },
      { text: "E-MAIL", value: "email", align: "start" },
      { text: "OPÇÕES", value: "opcoes", align: "start" }
    ]
  }),

  methods: {
    logout() {
      localStorage.removeItem("token");
      return this.$router.push("/");
    },
    openDisease(item) {
      let payload = {
        id: item,
      };
      this.$store.dispatch("deleteUser", payload);
    },
    getColor(type) {
      if (type === "investigação") return "green";
      else if (type === "pendente") return "orange";
      else return "orange";
    }
  },

  computed: {
    ...mapHelper
  },

  created() {
    this.$store.dispatch("getAllUsers");
  }
};
</script>

<style scoped></style>
