<template>
  <div id="app">
    <index-vue></index-vue>
  </div>
</template>

<script>
import IndexVue from "./views/home/IndexVue";
export default {
  name: "App",
  components: {IndexVue},
};
</script>
