<template>
  <div>
    <v-row>
      <v-card elevation="0" class="mx-auto m-txt-center mt-16" width="600">
        <v-card-text class="text-uppercase m-size-font-14 font-weight-bold"
          >Quadro Inicial</v-card-text
        >
      </v-card>
    </v-row>
    <v-row>
      <v-card elevation="0" width="800" class="mx-auto m-txt-center m-center">
        <v-sheet
          color="transparent"
          min-height="50"
          elevation="0"
          class="mx-auto m-txt-center m-center"
        >
          <v-row justify="center">
            <v-col cols="4" xs="12" md="4" style="min-width: 250px;">
              <v-card :to="{ name: 'payment' }" color="indigo lighten-5">
                <v-card-text
                  ><v-icon size="56" color="primary"
                    >mdi-file-chart-outline</v-icon
                  ></v-card-text
                >
                <v-card-text> Gerar nota de pagamento </v-card-text>
              </v-card>
            </v-col>

            <v-col cols="4" xs="12" md="4" style="min-width: 250px;">
              <v-card :to="{ name: 'express' }" color="indigo lighten-5">
                <v-card-text
                  ><v-icon size="56" color="primary"
                    >mdi-account-credit-card</v-icon
                  ></v-card-text
                >
                <v-card-text> Gerar Referência</v-card-text>
              </v-card>
            </v-col>

            <v-col cols="4" xs="12" md="4" style="min-width: 250px;">
              <v-card :to="{ name: 'bulk' }" color="indigo lighten-5">
                <v-card-text
                ><v-icon size="56" color="primary"
                >mdi-account-credit-card</v-icon
                ></v-card-text
                >
                <v-card-text> Referência em Massa</v-card-text>
              </v-card>
            </v-col>

            <v-col
              v-if="getRoles === 'admin' || getRoles === 'root'"
              cols="4"
              xs="12"
              md="4"
              lg="4"
              xl="4"
              style="min-width: 250px"
            >
              <v-card :to="{ name: 'create.user' }" color="indigo lighten-5">
                <v-card-text
                  ><v-icon size="56" color="primary"
                    >mdi-account-multiple-check</v-icon
                  >
                </v-card-text>
                <v-card-text> Criar utilizadores </v-card-text>
              </v-card>
            </v-col>
            <v-col
              v-if="getRoles === 'root'"
              cols="4"
              xs="12"
              md="4"
              lg="4"
              xl="4"
              style="min-width: 250px"
            >
              <v-card :to="{ name: 'config' }" color="indigo lighten-5">
                <v-card-text
                  ><v-icon size="56" color="primary">mdi-cog</v-icon>
                </v-card-text>
                <v-card-text> Config </v-card-text>
              </v-card>
            </v-col>

            <v-col
              cols="4"
              xs="12"
              md="4"
              lg="4"
              xl="4"
              style="min-width: 250px"
            >
              <v-card :to="{ name: 'create.product' }" color="indigo lighten-5">
                <v-card-text
                  ><v-icon size="56" color="primary"
                    >mdi-clipboard-list-outline</v-icon
                  >
                </v-card-text>
                <v-card-text> Criar produto </v-card-text>
              </v-card>
            </v-col>

            <v-col
              cols="4"
              xs="12"
              md="4"
              lg="4"
              xl="4"
              style="min-width: 250px"
            >
              <v-card
                :to="{ name: 'confirm.payment' }"
                color="indigo lighten-5"
              >
                <v-card-text
                  ><v-icon size="56" color="primary">mdi-text-search</v-icon>
                </v-card-text>
                <v-card-text> Confirmar pagamento </v-card-text>
              </v-card>
            </v-col>

            <v-col
              v-if="getRoles === 'admin' || getRoles === 'root'"
              cols="4"
              xs="12"
              md="4"
              lg="4"
              xl="4"
              style="min-width: 250px"
            >
              <v-card :to="{ name: 'list.user' }" color="indigo lighten-5">
                <v-card-text
                  ><v-icon size="56" color="primary">mdi-account</v-icon>
                </v-card-text>
                <v-card-text> Listar Utilizadores </v-card-text>
              </v-card>
            </v-col>

            <v-col
              cols="4"
              xs="12"
              md="4"
              lg="4"
              xl="4"
              style="min-width: 250px"
            >
              <v-card :to="{ name: 'list.product' }" color="indigo lighten-5">
                <v-card-text
                  ><v-icon size="56" color="primary">mdi-cart</v-icon>
                </v-card-text>
                <v-card-text> Listar Produtos </v-card-text>
              </v-card>
            </v-col>

            <v-col
              cols="4"
              xs="12"
              md="4"
              lg="4"
              xl="4"
              style="min-width: 250px"
            >
              <v-card @click="logout" color="indigo lighten-5">
                <v-card-text
                  ><v-icon size="56" color="primary">mdi-logout</v-icon>
                </v-card-text>
                <v-card-text> Sair </v-card-text>
              </v-card>
            </v-col>
          </v-row>

          <div style="padding-top: 100px"></div>
        </v-sheet>
      </v-card>
    </v-row>
  </div>
</template>

<script>
import { mapHelper } from "../../store/helper";

export default {
  name: "Dashboard",

  methods: {
    logout() {
      localStorage.removeItem("token");
      return this.$router.push("/");
    }
  },
  created() {
    this.$store.dispatch("getAllProducts");
    this.$store.dispatch("getRole");
    this.$store.dispatch("getEntities");
    this.$store.dispatch("getAllUsers");

  },
  computed: {
    ...mapHelper
  }
};
</script>

<style scoped></style>
