<template>
  <div>
    <v-card elevation="0" width="600" class="mx-auto m-txt-center mt-12">
      <v-card-text class="text-uppercase mt-12 m-size-font-14 font-weight-bold"
        >Confirmar Pagamento</v-card-text
      >
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-text-field
          v-model="reference"
          :rules="txtRules"
          hint="Campo é obrigatório"
          label="Digite a referência"
        ></v-text-field>
      </v-form>
      <div class="mt-6 text-left">
        <v-btn @click="createSick" depressed color="primary"> Confirmar </v-btn>
        <v-btn :to="{ name: 'home' }" depressed color="success" class="mx-2">
          Voltar
        </v-btn>
      </div>
    </v-card>
    <overlay></overlay>
    <confirm-pay></confirm-pay>
  </div>
</template>

<script>
import { mapHelper } from "@/store/helper";
import overlay from "@/components/helpers/overlay";
import ConfirmPay from "@/components/dialog/ConfirmPay";
export default {
  name: "ConfirmPayment",
  components: {ConfirmPay, overlay },
  data: () => ({
    valid: false,
    reference: "",
    txtRules: [
      v => v.length <= 60 || "Máximo 60 caracteres",
      v => !!v || "Campo obrigatório"
    ]
  }),
  methods: {
    createSick() {
      if (this.$refs.form.validate()) {
        const payload = {
          reference: this.reference,
        };
        this.$store.dispatch("confirmPayment", payload);
      }
    }
  },

  computed: {
    ...mapHelper
  }
};
</script>

<style scoped></style>
