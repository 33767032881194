<template>
  <div>
    <v-card elevation="0" width="600" class="mx-auto m-txt-center mt-12">
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-row class="mt-12">
          <v-col cols="6">
            <v-text-field
              v-model="first_name"
              hint="Digite o primeiro nome"
              label="Digite o primeiro nome"
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="last_name"
              hint="Digite o último nome"
              label="Digite o último nome"
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="email"
              hint="Digite o e-mail"
              label="Digite o e-mail"
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="password"
              hint="Digite a palavra-passe"
              label="Palavra-Passe"
            ></v-text-field>
          </v-col>

          <v-col cols="6">
            <v-combobox
              outlined
              v-model="select"
              :items="items"
              label="Escolha a responsabilidade"
            ></v-combobox>
            <!--          <v-select-->
            <!--            v-model="role"-->
            <!--            counter="60"-->
            <!--            hint="Escolha a responsabilidade"-->
            <!--            label="Papeis"-->
            <!--          ></v-select>-->
          </v-col>
        </v-row>
      </v-form>
      <div class="mt-6 text-left">
        <v-btn @click="createAccount" depressed color="primary">
          Registar Utilizador</v-btn
        >
        <v-btn :to="{ name: 'home' }" depressed color="success" class="mx-2">
          Voltar
        </v-btn>
      </div>

    </v-card>
    <overlay></overlay>
  </div>
</template>

<script>
import Overlay from "@/components/helpers/overlay";
export default {
  name: "Employer",
  components: {Overlay},
  data: () => ({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    select: "",
    items: ["Administrador", "Gerente", "Operador"]
  }),
  methods: {
    createAccount() {
      if (this.$refs.form.validate()) {
        const payload = {
          first_name: this.first_name,
          last_name: this.last_name,
          email: this.email,
          password: this.password,
          role: this.select
        };
        this.$store.dispatch("createUser", payload);
      }
    }
  }
};
</script>

<style scoped></style>
