<template>
  <div>
    <v-card elevation="0" width="600" class="mx-auto m-txt-center mt-12">
      <v-card-text class="text-uppercase mt-12 m-size-font-14 font-weight-bold"
      >Gerar Referência em Massa (Até 50 por documento)</v-card-text
      >
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-text-field
            v-model="qty"
            :rules="bulkRules"
            hint="Este campo é obrigatório"
            label="Quantidade"
            type="number"

        ></v-text-field>

        <v-text-field
            v-model="price"
            :rules="txtRules"
            hint="Este campo é obrigatório"
            label="Digite o preço do produto"
            @input="formatCurrency"
        ></v-text-field>
      </v-form>
      <div class="mt-6 text-left">
        <v-btn @click="createReference" depressed color="primary">
          Gerar Referência em massa
        </v-btn>
        <v-btn :to="{ name: 'home' }" depressed color="success" class="mx-2">
          Voltar
        </v-btn>
      </div>
    </v-card>
    <overlay></overlay>
  </div>
</template>

<script>
import { mapHelper } from "@/store/helper";
import overlay from "@/components/helpers/overlay";
export default {
  name: "Bulk",
  components: { overlay },
  data: () => ({
    name: "",
    qty: "",
    valid: false,
    price: "",
    iva: "0",
    txtRules: [
      v => v.length <= 60 || "Máximo 60 caracteres",
      v => !!v || "Campo obrigatório",
      v => /^[0-9,.]*$/.test(v) || "Deve ser valor monetário"
    ],
    bulkRules: [
      v => !!v || "Campo obrigatório",
    ]
  }),
  methods: {
    formatCurrency() {
      // Remove all non-numeric and non-decimal characters
      let formattedValue = this.price.replace(/[^0-9.]/g, "");

      // Separate decimals by a dot and remove leading zeros
      const parts = formattedValue.split(".");
      if (parts.length > 1) {
        parts[0] = parts[0].replace(/^0+/, "");
        formattedValue = parts.join(".");
      }

      // Add a comma for thousands separation
      const regex = /(\d)(?=(\d{3})+(?!\d))/g;
      formattedValue = formattedValue.replace(regex, "$1,");

      // Update the v-model to display the formatted value
      this.price = formattedValue;
    },

    createReference() {
      const ReformattedValue = this.price.replace(/[^0-9.]/g, "");
      if (this.$refs.form.validate()) {
        const payload = {
          price: parseFloat(ReformattedValue),
          qty: this.qty
        };
        this.$store.dispatch("bulk", payload);
      }
    }
  },

  computed: {
    ...mapHelper,

    txtRules() {
      return [this.$v.price.v, this.$v.price.v, this.$v.price.v];
    }
  }
};
</script>

<style scoped></style>
