<template>
  <div>
    <v-row class="hidden-print">
      <v-card elevation="0" class="mx-auto m-txt-center mt-12" width="600">
        <v-card-text
          class="text-uppercase mt-12 m-size-font-14 font-weight-bold"
          >Nota de Pagamento</v-card-text
        >
      </v-card>
    </v-row>
    <v-row class="hidden-print">
      <v-card
        elevation="0"
        width="700"
        class="mx-auto m-txt-center hidden-print"
      >
        <v-sheet color="transparent" min-height="50" elevation="0">
          <!--          <v-card-title>-->
          <!--          </v-card-title>-->
          <!--          @click:row="showSingleReport"-->

          <v-card-text>
            <v-text-field
              v-model="client"
              label="Digite o Nome do Cliente"
              hide-details
              solo
            ></v-text-field>
            <v-spacer></v-spacer>
          </v-card-text>

          <v-card-text class="mt-n6">
            <v-text-field
              v-model="nif"
              label="Digite o NIF"
              hide-details
              solo
            ></v-text-field>
            <v-spacer></v-spacer>
          </v-card-text>

          <div class="mt-6 text-right mb-4">
            <v-btn @click="addItem" depressed color="success" class="mr-2">
              Adicionar Item
            </v-btn>
            <v-btn
              v-if="getItemInStore.length > 0"
              @click="printToAll"
              depressed
              color="primary"
              class="mr-2"
            >
              Imprimir
            </v-btn>
            <v-btn :to="{ name: 'home' }" depressed color="success" class="mx-2">
              Voltar
            </v-btn>
          </div>

          <div style="padding: 0">
            <v-row no-gutters>
              <v-col class="">
                <v-card
                  class="pa-0"
                  style="background-color: #ffffff"
                  tile
                  elevation="0"
                  height="60"
                  width="100%"
                >
                  <v-sheet
                    color="transparent"
                    height="50"
                    elevation="0"
                    class="pa-2"
                    v-if="getItemInStore.length === 0"
                  >
                    <v-card-text class="mt-0"
                      ><v-icon size="20" class="mx-2"
                        >mdi-format-list-bulleted-square</v-icon
                      >Sem item adicionado.</v-card-text
                    >
                  </v-sheet>

                  <v-sheet
                    color="transparent"
                    min-height="50"
                    elevation="0"
                    v-if="getItemInStore.length > 0"
                  >
                    <v-simple-table class="pa-0 pl-0">
                      <template v-slot:default>
                        <thead class="text-left">
                          <tr>
                            <th class="text-left font-weight-bold">Item</th>
                            <th class="text-right font-weight-bold">IVA</th>
                            <th class="text-right font-weight-bold">P.Unit.</th>
                            <th class="text-right font-weight-bold">Qtd.</th>
                            <th class="text-right font-weight-bold">Total</th>
                            <th class="text-right font-weight-bold">Opção</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(item, k) in getItemInStore"
                            :key="item.name"
                            style="cursor: pointer"
                          >
                            <td class="text-left" @click="editItem(k, item)">
                              {{ item.name }}
                            </td>
                            <td class="text-right" @click="editItem(k, item)">
                              {{ item.iva }} %
                            </td>
                            <td class="text-right" @click="editItem(k, item)">
                              {{ price(item.iva, item.price) }} Akz
                            </td>
                            <td class="text-right" @click="editItem(k, item)">
                              {{ item.qtd }}
                            </td>
                            <td class="text-right" @click="editItem(k, item)">
                              {{ subTotal(item.iva, item.qtd, item.price) }}
                              Akz
                            </td>
                            <td class="text-right">
                              <v-icon color="red" @click="deleteItem(k, item)"
                                >mdi-trash-can</v-icon
                              >
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                    <div
                      style="
                border-bottom: 1px dashed #f6f6f6;
                padding: 10px;
                background-color: #ffffff;
              "
                    ></div>
                    <v-simple-table>
                      <template v-slot:default dense>
                        <tbody class="mt-6">
                          <tr>
                            <td
                              class="text-right font-weight-bold"
                              style="width: 500px; max-width: 500px"
                            >
                              Total
                            </td>
                            <td
                              class="text-right font-weight-bold"
                              style="min-width: 200px"
                            >
                              {{ totalGlobal(getItemInStore) }} Akz
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>

                    <div
                      style="
                border-bottom: 1px dashed #f6f6f6;
                padding: 10px;
                background-color: #ffffff;
              "
                    ></div>
                    <div style="padding-top: 100px"></div>
                  </v-sheet>
                </v-card>
              </v-col>
            </v-row>
          </div>

          <div
            style="
              border-bottom: 1px dashed #f6f6f6;
              padding: 10px;
              background-color: #ffffff;
              margin-top: 100px;
            "
          ></div>

          <div
            style="
              border-bottom: 1px dashed #f6f6f6;
              padding: 10px;
              background-color: #ffffff;
            "
          ></div>
          <div style="padding-top: 100px"></div>
        </v-sheet>
      </v-card>
    </v-row>

    <!--          PRINT INVOICE-->

    <div
      style="padding: 0; width: 350px; height: auto !important;"
      class="mx-auto m-txt-center show-invoice"
    >
      <div style="font-size: 12px; width: 350px;" class="mx-auto m-txt-center">
        <div class="text-left ml-2">                  {{ getEntities ? getEntities.enterprise : "" }}</div>
        <div class="text-left ml-2">NIF: {{ getEntities ? getEntities.nif : "" }}</div>
        <div class="text-left ml-2">TEL: {{ getEntities ? getEntities.cellphone : "" }}</div>
      </div>

      <div
        style="font-size: 12px; width: 350px;"
        class="mx-auto m-txt-center mt-12"
      >
        <div>NOTA DE PAGAMENTO</div>
      </div>
      <div
        style="font-size: 12px; width: 350px;"
        class="mx-auto m-txt-center mt-4"
      >
        <div class="text-left">
          ORDEM # {{ getOrder ? getOrder.order : "" }}
        </div>
        <div class="text-left">
          Cliente: {{ this.client ? this.client : "Cliente Final" }}
        </div>
        <div class="text-left">NIF: {{ this.nif }}</div>
      </div>
      <v-row no-gutters class="mt-8">
        <v-col>
          <v-card
            class="pa-0 mx-auto m-txt-center"
            style="background-color: #ffffff"
            tile
            elevation="0"
            height="100%"
            width="350"
          >
            <v-sheet
              color="transparent"
              height="50"
              elevation="0"
              class="pa-2"
              v-if="getItemInStore.length === 0"
            >
              <v-card-text class="mt-0"
                ><v-icon size="20" class="mx-2"
                  >mdi-format-list-bulleted-square</v-icon
                >Sem item adicionado.</v-card-text
              >
            </v-sheet>

            <v-sheet
              color="transparent"
              min-height="50"
              elevation="0"
              v-if="getItemInStore.length > 0"
            >
              <v-simple-table class="pa-0 pl-0">
                <template v-slot:default>
                  <thead class="text-left">
                    <tr>
                      <th class="text-left font-weight-bold">Item</th>
                      <th class="text-right font-weight-bold">IVA</th>
                      <th class="text-right font-weight-bold">P.Unit.</th>
                      <th class="text-right font-weight-bold">Qtd.</th>
                      <th class="text-right font-weight-bold">Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(item, k) in getItemInStore"
                      :key="item.name"
                      style="cursor: pointer"
                    >
                      <td class="text-left" @click="editItem(k, item)">
                        {{ item.name }}
                      </td>
                      <td class="text-right" @click="editItem(k, item)">
                        {{ item.iva }} %
                      </td>
                      <td class="text-right" @click="editItem(k, item)">
                        {{ price(item.iva, item.price) }} Akz
                      </td>
                      <td class="text-right" @click="editItem(k, item)">
                        {{ item.qtd }}
                      </td>
                      <td class="text-right" @click="editItem(k, item)">
                        {{ subTotal(item.iva, item.qtd, item.price) }}
                        Akz
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
              <div
                style="
                border-bottom: 1px dashed #f6f6f6;
                padding: 10px;
                background-color: #ffffff;
              "
              ></div>
              <v-simple-table>
                <template v-slot:default dense>
                  <tbody class="mt-6">
                    <tr>
                      <td
                        class="text-right font-weight-bold"
                        style="width: 500px; max-width: 500px"
                      >
                        Total
                      </td>
                      <td
                        class="text-right font-weight-bold"
                        style="min-width: 200px"
                      >
                        {{ totalGlobal(getItemInStore) }} Akz
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>

              <div
                style="font-size: 12px; width: 350px; margin-top: 15px"
                class="mx-auto m-txt-center"
              >
                <div class="text-left ml-2">
                  Pagamento por referência:
                </div>
              </div>

              <div
                style="font-size: 12px; width: 350px; margin-top: 30px"
                class="mx-auto m-txt-center"
              >
                <div class="text-left ml-2">
                  Entidade: {{ getEntities ? getEntities.entity : "" }}
                </div>
                <div class="text-left ml-2">
                  Referência:
                  {{ getOrder ? getOrder.reference : "" }}
                </div>
                <div class="text-left ml-2">
                  Montante: {{ totalGlobal(getItemInStore) }} Akz
                </div>
              </div>

              <div
                style="font-size: 10px; width: 350px; margin-top: 30px"
                class="mx-auto m-txt-center"
              >
                <div class="text-left ml-2">
                  Operador: {{ getOrder ? getOrder.operator : "" }}.
                </div>
                <div class="text-left ml-2">
                  Data: {{new Date().toDateString()}}.
                </div>
                <div class="text-left ml-2">
                  OBS: Esta nota não serve de comprovativo de pagamento.
                </div>
              </div>

              <div
                style="
                border-bottom: 1px dashed #f6f6f6;
                padding: 10px;
                background-color: #ffffff;
              "
              ></div>
              <div style="padding-top: 100px"></div>
            </v-sheet>
          </v-card>
        </v-col>
      </v-row>
    </div>

    <!--          END PRINT INVOICE-->

    <dialog-status-disease></dialog-status-disease>
    <add-item></add-item>
    <edit-item></edit-item>
    <overlay></overlay>
  </div>
</template>

<script>
import { mapHelper } from "@/store/helper";
import DialogStatusDisease from "../../components/helpers/DialogStatusDisease";
import eventBus from "../../main";

import AddItem from "@/components/dialog/AddItem";
import EditItem from "@/components/dialog/EditItem";
import Overlay from "../../components/helpers/overlay";

export default {
  name: "PageReport",
  components: {Overlay, EditItem, AddItem, DialogStatusDisease },
  data: () => ({
    search: "",
    client: "",
    nif: ""
  }),
  methods: {
    addItem() {
      let payload = {
        dialog: true
      };
      eventBus.$emit("openDialogAddItem", payload);
    },
    deleteItem(item) {
      const value = {
        item: item
      };
      this.$store.dispatch("deleteItem", value);
    },

    editItem(k, obj) {
      const value = {
        key: k,
        dialog: true,
        object: obj
      };
      eventBus.$emit("openDialogEditItem", value);
      //this.$store.dispatch("setModalUpdateArticle", obj.name);
    },
    subTotal(iva, qty, price) {
      const calcIva = (parseFloat(iva) * parseFloat(price)) / 100;
      let total = parseFloat(price) * parseInt(qty) + parseFloat(calcIva);

      if (isNaN(total)) return 0;
      if (total < 0) {
        let payload = {
          color: "error",
          text:
            "SubTotal não pode ser negativo. Verifique se aplicou desconto superior ao total",
          errorLogin: true
        };
        return eventBus.$emit("openAlert", payload); // emit the event to the bus
      }

      return new Intl.NumberFormat("de-DE").format(total);
    },

    totalGlobal(obj) {
      let sum = 0;

      obj.map(function(value) {
        let price = value.price;
        let iva = value.iva;

        let calcIva = (parseFloat(iva) * parseFloat(price)) / 100;

        let qty = value.qtd;
        let result = parseFloat(price) * parseInt(qty) + parseFloat(calcIva);
        sum += result;
      });
      if (isNaN(sum)) return 0;
      return this.currencyFormat(parseFloat(sum));
    },

    totalGlobalSave(obj) {
      let sum = 0;

      obj.map(function(value) {
        let price = value.price;
        let iva = value.iva;

        let calcIva = (parseFloat(iva) * parseFloat(price)) / 100;

        let qty = value.qtd;
        let result = parseFloat(price) * parseInt(qty) + parseFloat(calcIva);
        sum += result;
      });
      if (isNaN(sum)) return 0;
      return parseFloat(sum);
    },

    currencyFormat(currency) {
      return new Intl.NumberFormat("de-DE").format(currency);
    },

    price(iva, price) {
      // const calcIva = (parseFloat(iva) * parseFloat(price)) / 100;
      let p = parseFloat(price);
      // let p = parseFloat(price) + parseFloat(calcIva);
      if (isNaN(p)) return 0;
      return new Intl.NumberFormat("de-DE").format(p);
    },
    printToAll() {
      let store = this.$store.getters.getItemInStore;
      let payload = {
        totalPrice: this.totalGlobalSave(store),
        client: this.client ? this.client : "Cliente Final",
        nif: this.nif ? this.nif : "Cliente Final",
        products: store
      };
      this.$store.dispatch("createInvoice", payload);
      // console.log(payload);
      // window.print();
    }
  },

  computed: {
    ...mapHelper
  }
};
</script>

<style>
.show-invoice {
  display: none;
}

@media print {
  .hidden-print,
  .hidden-print * {
    display: none !important;
  }
  .show-invoice {
    display: block;
  }

  body,
  html * {
    width: 360px;
    text-align: center;
    align-content: center;
    margin-left: auto;
    margin-right: auto;
  }
  @page {
    margin: 10px;
  }
}
</style>
