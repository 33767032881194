<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" max-width="600">
      <v-card>
        <v-card-text>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Filtrar Item"
            single-line
            hide-details
          ></v-text-field>
          <v-spacer></v-spacer>
        </v-card-text>

        <v-data-table
          style="cursor: pointer"
          height="350"
          :headers="headers"
          :items="getProducts"
          :search="search"
          single-select
          item-key="name"
          @click:row="insertArticle"
          disable-pagination
        >
        </v-data-table>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import eventBus from "../../main";
import { mapHelper } from "@/store/helper";

export default {
  name: "AddItem",
  data: () => ({
    dialog: false,
    item: "",
    search: "",
    headers: [
      {
        text: "Artigo",
        align: "start",
        filterable: true,
        value: "name",
        sortable: false
      },
      { text: "Preço", value: "price", align: "right", sortable: false }
    ]
  }),
  methods: {
    removeItem() {
      this.$store.dispatch("removeItemOnStore", this.item);
      this.dialog = false;
    },
    insertArticle(item) {
      if (item) {
        this.$store.dispatch("setStoreProduct", item);
        let payload = {
          dialog:false
        };
        eventBus.$emit("openDialogAddItem", payload);
      }
    }
  },

  computed: {
    ...mapHelper
  },

  created() {
    this.$store.dispatch("getAllProducts");
    const self = this;
    eventBus.$on("openDialogAddItem", function(event) {
      self.dialog = event.dialog;
      // self.item = event.item;
    });
  }
};
</script>

<style scoped></style>
