<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" max-width="600" persistent>
      <v-card elevation="0">
        <v-row justify="center">
          <v-card-text> </v-card-text>
          <v-card-text>
            <v-container>
              <v-form novalidate ref="form" lazy-validation>
                <v-col cols="12">
                  <v-text-field
                    v-model="obj.qtd"
                    type="number"
                    label="Quantidade"
                    min="1"
                    id="qty"
                    required
                    :rules="quantityRule"
                    hint="Define a quantidade desejada"
                  ></v-text-field>
                </v-col>
<!--                <v-col cols="12">-->
<!--                  <v-text-field-->
<!--                      v-model="obj.price"-->
<!--                      type="number"-->
<!--                      label="Quantidade"-->
<!--                      min="1"-->
<!--                      id="qty"-->
<!--                      required-->
<!--                      :rules="quantityRule"-->
<!--                      hint="Define a quantidade desejada"-->
<!--                  ></v-text-field>-->
<!--                </v-col>-->
              </v-form>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-btn color="success darken-1" @click="updateArticle">
              Fechar
            </v-btn>
          </v-card-actions>
        </v-row>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import eventBus from "../../main";
import { mapHelper } from "@/store/helper";
export default {
  name: "EditItem",
  data: () => ({
    dialog: false,
    obj: Object,
    globalZeroRule: [
      v => {
        if (parseInt(v) >= 0) return true;
        return "Valor é requerido";
      }
    ],
    quantityRule: [
      v => !!v || "Quantidade é requerida",
      v => (v && v >= 1) || "Quantidade mínima é 1"
    ],
    priceRule: [
      v => !!v || "preço é requerido",
      v => (v && v >= 1) || "Quantidade mínima é 1"
    ]
  }),

  methods: {
    cancel() {
      this.sheet = false;
    },
    updateArticle() {
      //this.$refs.form.validate()
      let qty = document.getElementById("qty");
      if (qty.value === "" || qty.value < 1) {
        return false;
      }
      this.dialog = false;
    }
  },
  computed: {
    ...mapHelper
  },
  created() {
    const self = this;
    eventBus.$on("openDialogEditItem", function(event) {
      self.dialog = event.dialog;
      self.obj = event.object;
    });
  }
};
</script>

<style scoped></style>
