<template>
  <div>
    <v-card elevation="0" width="600" class="mx-auto m-txt-center mt-12">
      <v-card-text class="text-uppercase mt-12 m-size-font-14 font-weight-bold"
        >Produtos</v-card-text
      >
    </v-card>

    <v-row>
      <v-card elevation="0" width="700" class="mx-auto m-txt-center">
        <div class="mt-6 text-left">
          <v-btn :to="{ name: 'home' }" depressed color="success" class="mx-2">
            Voltar
          </v-btn>
        </div>
        <v-sheet color="transparent" min-height="50" elevation="0">
          <v-card-title>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Filtrar resultados"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <!--          @click:row="showSingleReport"-->
          <v-data-table
            :headers="headers"
            :items="getProducts"
            :sort-desc="true"
            :disable-items-per-page="false"
            multi-sort
            class="elevation-0"
            :items-per-page-options="[5, 10, 15]"
            :search="search"
            :items-per-page="6"
          >
            <template v-slot:item.price="{ item }">
              <span v-html="currencyFormat(item.price) + ' Kz'"> </span>
            </template>

            <template v-slot:item.opcoes="{ item }">
              <v-btn class="ma-2" fab color="red" x-small>
                <v-icon
                  class="text--white"
                  style="color: white !important"
                  @click="openDisease(item.id)"
                  >mdi-delete</v-icon
                >
              </v-btn>

              <v-btn class="ma-2" fab color="primary" x-small>
                <v-icon
                  class="text--white"
                  style="color: white !important"
                  @click="edit(item)"
                  >mdi-pencil</v-icon
                >
              </v-btn>
            </template>
          </v-data-table>
        </v-sheet>
      </v-card>
    </v-row>
    <overlay></overlay>
  </div>
</template>

<script>
import { mapHelper } from "../../store/helper";
import Overlay from "../helpers/overlay";
import router from "../../router";
import eventBus from "../../main";

export default {
  name: "ListUser",
  components: { Overlay },
  data: () => ({
    search: "",
    headers: [
      {
        text: "PRODUTO",
        align: "start",
        sortable: true,
        value: "name"
      },
      { text: "PREÇO", value: "price", align: "start" },
      { text: "OPÇÕES", value: "opcoes", align: "start" }
    ]
  }),

  methods: {
    currencyFormat(currency) {
      return new Intl.NumberFormat("de-DE").format(currency);
    },
    logout() {
      localStorage.removeItem("token");
      return this.$router.push("/");
    },
    openDisease(item) {
      let payload = {
        id: item
      };
      this.$store.dispatch("deleteProduct", payload);
    },

    edit(item) {
      eventBus.$emit("receiptItemEdit", item);
      //saveEditItem
      this.$store.dispatch("saveEditItem", item);
      router.push({ name: "edit.product" });
      // let payload = {
      //   id: item
      // };

    },
    getColor(type) {
      if (type === "investigação") return "green";
      else if (type === "pendente") return "orange";
      else return "orange";
    }
  },

  computed: {
    ...mapHelper
  },

  created() {
    this.$store.dispatch("getAllUsers");
  }
};
</script>

<style scoped></style>
