<template>
  <v-app>
    <div class="mx-auto m-center m-txt-center">
      <v-card elevation="0" width="350" class="mx-auto m-center m-txt-center">
        <v-card-text class="font-weight-bold m-size-font-24 black--text"
          >GR REFERENCE</v-card-text
        >
      </v-card>
      <v-card
        width="350"
        class="mx-auto m-center m-txt-center ma-2"
        style="margin-top: 100px"
      >
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-text-field
            v-model="email"
            :rules="emailRules"
            hint="Insira o correio eletrónico"
            label="Correio eletrónico"
          >
          </v-text-field>

          <v-text-field
            v-model="pwd"
            :rules="pwdRules"
            type="password"
            hint="Insira a palavra-passe"
            label="Palavra-passe"
          >
          </v-text-field>
        </v-form>

        <div class="mt-6">
          <v-btn @click="createAccount" depressed color="primary">
            Iniciar Sessão
          </v-btn>
        </div>
      </v-card>
    </div>
    <overlay></overlay>
  </v-app>
</template>

<script>
import Overlay from "../../components/helpers/overlay";
export default {
  name: "Login",
  components: { Overlay },
  data: () => ({
    valid: true,
    email: "",
    pwd: "",
    pwdRules: [
      v => v.length >= 6 || "Mínimo 6 caracteres",
      v => !!v || "Campo obrigatório"
    ],
    emailRules: [
      v => v.trim().split(" ").length <= 40 || "Máximo 40 caracteres",
      v => /.+@.+\..+/.test(v) || "Correio inválido",
      v => !!v || "Campo obrigatório"
    ]
  }),

  methods: {
    createAccount() {
      if (this.$refs.form.validate()) {
        let payload = {
          email: this.email,
          password: this.pwd,
          device: "web"
        };
        this.$store.dispatch("login", payload);
      }
    }
  }
};
</script>

<style scoped></style>
