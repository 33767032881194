<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="290">
      <v-card>
        <v-card-title class="text-h5"> Actualizar Status </v-card-title>
        <v-card-text class="mt-4">
          <v-combobox
            outlined
            v-model="select"
            :items="items"
            label="Escolha o Status"
          ></v-combobox>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="closeModal">
            Rejeitar
          </v-btn>
          <v-btn color="green darken-1" text @click="closeModal">
            Actualizar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import eventBus from "../../main";

export default {
  name: "DialogStatusDisease",
  data: () => ({
    dialog: false,
    item: "",
    select: "",
    items: ["Pendente", "Investigação"],
  }),
  methods:{
    closeModal(){
      this.dialog = false;
      this.select = "";
    }
  },
  created() {
    const self = this;
    eventBus.$on("openDialogStatusDisease", function (payload) {
      self.dialog = payload.open;
      self.item = payload.item;
    });
  },
};
</script>

<style scoped></style>
