<template>
  <div>
    <v-card elevation="0" width="600" class="mx-auto m-txt-center mt-12">
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-row class="mt-12">
          <v-col cols="6">
            <v-text-field
              v-model="enterprise"
              hint="Nome Da Empresa"
              label="Digite o nome da Empresa"
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="nif"
              hint="Digite o NIF da Empresa"
              label="Digite o NIF da Empresa"
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="entity"
              hint="Digite o número de Entidade"
              label="Digite o número de Entidade"
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="cellphone"
              hint="Digite o Telefone da Empresa"
              label="Digite o Telefone da Empresa"
            ></v-text-field>
          </v-col>

          <v-col cols="12">
            <v-text-field
              v-model="key_point"
              hint="Digite a Key"
              label="Digite a Key"
            ></v-text-field>
          </v-col>

          <v-col cols="12">
            <v-text-field
              v-model="address"
              hint="Digite o Endereço da Empresa"
              label="Digite o Endereço da Empresa"
            ></v-text-field>
          </v-col>

          <v-col cols="6">
            <v-text-field
              v-model="first_name"
              hint="Primeiro nome - Administrador"
              label="Digite o primeiro nome"
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="last_name"
              hint="Último nome - Administrador"
              label="Digite o último nome"
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="email"
              hint="Digite o e-mail"
              label="Digite o e-mail"
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="password"
              hint="Digite a password"
              label="Digite a passwoerd"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-form>
      <div class="mt-6 text-left">
        <v-btn @click="createAccount" depressed color="primary">
          Registar Entidade</v-btn
        >
        <v-btn :to="{ name: 'home' }" depressed color="success" class="mx-2">
          Voltar
        </v-btn>
      </div>
    </v-card>
    <overlay></overlay>
  </div>
</template>

<script>
import Overlay from "../../components/helpers/overlay";
export default {
  name: "Config",
  components: { Overlay },
  data: () => ({
    enterprise: "",
    nif: "",
    entity: "",
    select: "",
    cellphone: "",
    address: "",
    key_point: "",

    first_name: "",
    last_name: "",
    email: "",
    role: "",
    password: ""
  }),
  methods: {
    createAccount() {
      if (this.$refs.form.validate()) {
        const payload = {
          enterprise: this.enterprise,
          nif: this.nif,
          entity: this.entity,
          cellphone: this.cellphone,
          address: this.address,
          key_point: this.key_point,

          first_name: this.first_name,
          last_name: this.last_name,
          email: this.email,
          password: this.password
        };
        this.$store.dispatch("createEntity", payload);
      }
    }
  }
};
</script>

<style scoped></style>
