<template>
  <div>
    <v-card elevation="0" width="600" class="mx-auto m-txt-center mt-12">
      <v-card-text class="text-uppercase mt-12 m-size-font-14 font-weight-bold"
        >Referência</v-card-text
      >
      <v-card-text>
        <qrcode-vue :value="getOptReference" level="H"></qrcode-vue>
      </v-card-text>

      <v-card-text>
        <div>
          Entidade: {{ getReference.entity ? getReference.entity : "" }}
        </div>
        <div>
          Referência: {{ getReference.reference ? getReference.reference : "" }}
        </div>
        <div>
          Montante: {{ getReference.price ? getReference.price : "" }} Akz
        </div>

        <div>
          Expira em: {{ getReference.expiry ? getReference.expiry : "" }}
        </div>
      </v-card-text>

      <v-card-text>
        <ShareNetwork
          network="WhatsApp"
          title="Referencia Para Pagamento do pedido."
          :description="getOptReference"
          url=""
          style="background-color: #25d366; padding: 10px; color: white; border-radius: 4px"
        >
          <span><v-icon color="white" class="mx-1">mdi-whatsapp</v-icon></span>
        </ShareNetwork>

        <ShareNetwork
          class="mx-2"
          network="telegram"
          title="Referencia Para Pagamento do pedido."
          :description="getOptReference"
          url=""
          style="background-color: #0088cc; padding: 10px; color: white; border-radius: 4px"
        >
          <span><v-icon color="white" class="mx-1">fa-telegram</v-icon></span>
        </ShareNetwork>

        <ShareNetwork
          class="mx-1"
          network="SMS"
          title="Referencia Para Pagamento do pedido."
          :description="getOptReference"
          url=""
          style="background-color: #333333; padding: 10px; color: white; border-radius: 4px"
        >
          <span
            ><v-icon color="white" class="mx-1"
              >mdi-message-text-outline</v-icon
            ></span
          >
        </ShareNetwork>

        <ShareNetwork
          class="mx-1"
          network="messenger"
          title="Referencia Para Pagamento do pedido."
          :description="getOptReference"
          url=""
          style="background-color: #0084ff; padding: 10px; color: white; border-radius: 4px"
        >
          <span
            ><v-icon color="white" class="mx-1"
              >mdi-facebook-messenger</v-icon
            ></span
          >
        </ShareNetwork>
      </v-card-text>

      <div class="mt-6">
        <v-btn @click="copyTo" depressed color="primary">
          Copiar Referência
        </v-btn>
        <v-btn :to="{ name: 'home' }" depressed color="success" class="mx-2">
          Voltar
        </v-btn>
      </div>
    </v-card>
    <overlay></overlay>
  </div>
</template>

<script>
import { mapHelper } from "../../store/helper";
import overlay from "../../components/helpers/overlay";
import QrcodeVue from "qrcode.vue";
import Vue from "vue";
export default {
  name: "AcceptReference",
  components: { overlay, QrcodeVue },
  data: () => ({
    txtRules: [
      v => v.length <= 60 || "Máximo 60 caracteres",
      v => !!v || "Campo obrigatório"
    ]
  }),
  methods: {
    copyTo() {
      let textHeader = "Caro Cliente, pague usando a seguinte referencia:";
      let textEntity =
        "Entidade: " + this.$store.getters.getReference.entity;
      let textReference =
        "Referencia: " + this.$store.getters.getReference.reference;
      let textAmount =
        "Montante: " + this.$store.getters.getReference.price;
      let text =
        textHeader + "\n" + textEntity + "\n" + textReference + "\n" + textAmount + " kzs";


      navigator.clipboard.writeText(text).then(
        function() {
          Vue.toasted.success("Referência copiada com sucesso!", {
            theme: "bubble",
            position: "top-center",
            duration: 2000
          });
        },
        function() {
          Vue.toasted.error("Falha ao copiar!", {
            theme: "bubble",
            position: "top-center",
            duration: 2000
          });
        }
      );
    }
    // createReference() {
    //   if (this.$refs.form.validate()) {
    //     const payload = {
    //       price: this.price
    //     };
    //     this.$store.dispatch("createItem", payload);
    //   }
    // }
  },

  computed: {
    ...mapHelper
  }
};
</script>

<style scoped></style>
