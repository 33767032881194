<template>
  <div class="hidden-print">
    <v-app-bar color="primary accent-4" dense dark>
      <v-app-bar-nav-icon :to="{ name: 'home' }"></v-app-bar-nav-icon>
      <v-toolbar-title>GR REFERENCE</v-toolbar-title>

      <v-spacer></v-spacer>
      <span class="m-size-font-12">
        {{ profile }}
      </span>
      <v-menu left bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item @click="() => {}">
            <v-list-item-title
              ><v-icon class="ma-2 black--text">mdi-account</v-icon>
              Perfil</v-list-item-title
            >
          </v-list-item>
          <v-list-item @click="logout">
            <v-list-item-title
              ><v-icon class="ma-2 black--text">mdi-logout</v-icon>Terminar
              Sessão</v-list-item-title
            >
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
  </div>
</template>

<script>
export default {
  name: "NavBar",
  data: () => ({
    drawer: false,
    group: null,
    profile: localStorage.getItem("profile")
  }),

  methods: {
    getProfile() {
      return localStorage.getItem("profile");
    },
    logout() {
      localStorage.removeItem("token");
      this.$router.push("/");
    },
  },

  watch: {
    group() {
      this.drawer = false;
    },
  },
};
</script>

<style scoped></style>
