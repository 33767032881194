<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" max-width="300">
      <v-card>
        <v-card-text class="mx-auto m-center m-txt-center">
          <v-icon color="success" size="80" class="mx-auto m-center m-txt-center" v-if="validate"
            >mdi-check-bold</v-icon
          >
          <v-icon color="red" size="80" v-if="!validate">mdi-close</v-icon>
        </v-card-text>
        <v-card-text class="mx-auto m-center m-txt-center">
          {{ info }}
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import eventBus from "@/main";

export default {
  name: "ConfirmPay",
  data: () => ({
    dialog: false,
    item: "",
    info: "",
    validate: false
  }),
  created() {
    this.$store.dispatch("getAllProducts");
    const self = this;
    eventBus.$on("openDialogConfirmPay", function(event) {
      self.dialog = event.dialog;
      self.info = event.info;
      self.validate = event.validate;
    });
  }
};
</script>

<style scoped></style>
