import Vue from "vue";
import Vuex from "vuex";
import eventBus from "../../../main";
import router from "../../../router";
import apiServices from "../../../../api/apiServices";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    users: [],
    listUsers: [],
    products: [],
    profile: "",
    roles: "",
    entities: "",
    store: [],
    reference: "",
    saveTotalGlobal: 0,
    operator: "",
    order: false,
    editItem: ""
    // order: {
    //   order: "",
    //   reference: ""
    // }
  },

  mutations: {
    SET_PRODUCTS(state, value) {
      state.products = value;
    },
    SET_STORE_ITEM(state, value) {
      state.store.push(value);
    },
    SET_TOTAL_GLOBAL(state, value) {
      state.saveTotalGlobal = value;
    },
    SET_DELETE_ITEM(state, value) {
      state.store.splice(value, 1);
    },
    SET_ORDER(state, value) {
      state.order = value;
      // state.order.order = value;
      // state.order.reference = value;
    },

    SET_REFERENCE_IN_STORE(state, value) {
      state.reference = value;
      // state.order.order = value;
      // state.order.reference = value;
    },

    SET_ALL_ROLES(state, value) {
      state.roles = value;
      // state.order.order = value;
      // state.order.reference = value;
    },

    SET_ALL_ENTITIES(state, value) {
      state.entities = value;
    },

    SET_ALL_USERS(state, value) {
      state.listUsers = value;
    },

    SET_EDIT_ITEM(state, value) {
      state.editItem = value;
    }
  },
  actions: {
    saveEditItem(context, value) {
      context.commit("SET_EDIT_ITEM", value);
    },
    saveTotalGlobal(context, value) {
      context.commit("SET_TOTAL_GLOBAL", value);
    },

    saveReference(context, value) {
      context.commit("SET_REFERENCE_IN_STORE", value);
    },

    saveOrder(context, value) {
      context.commit("SET_ORDER", value);
    },

    deleteItem(context, value) {
      context.commit("SET_DELETE_ITEM", value.item);
    },
    setStoreProduct(context, object) {
      context.commit("SET_STORE_ITEM", object);
    },
    getAllProducts(context) {
      eventBus.$emit("openOverlay", true);
      return apiServices
        .getProducts()
        .then(function(response) {
          if (response.status === 200) {
            let obj = response.data;
            context.commit("SET_PRODUCTS", obj);
          } else {
            Vue.toasted.error(response.data.message, {
              theme: "bubble",
              position: "top-center",
              duration: 4000
            });
          }
        })
        .catch(function() {
          Vue.toasted.error("Ups! Verifique a sua conexão de internet", {
            theme: "bubble",
            position: "top-center",
            duration: 3000
          });
        })
        .finally(function() {
          eventBus.$emit("openOverlay", false);
        });
    },

    deleteProduct(context, payload) {
      eventBus.$emit("openOverlay", true);
      return apiServices
        .callDeleteProduct(payload)
        .then(function(response) {
          if (response.status === 200) {
            Vue.toasted.success("Produto apagado com sucesso!", {
              theme: "bubble",
              position: "top-center",
              duration: 4000
            });
            return router.push("/home").catch(() => {
              throw new Error("Problem - Contact KP");
            });
          } else {
            Vue.toasted.error(response.data.message, {
              theme: "bubble",
              position: "top-center",
              duration: 4000
            });
          }
        })
        .catch(function() {
          Vue.toasted.error("Ups! Verifique a sua conexão de internet", {
            theme: "bubble",
            position: "top-center",
            duration: 3000
          });
        })
        .finally(function() {
          eventBus.$emit("openOverlay", false);
        });
    },

    editProduct(context, payload) {
      eventBus.$emit("openOverlay", true);
      return apiServices
        .callEditProduct(payload)
        .then(function(response) {
          if (response.status === 200) {
            Vue.toasted.success("Produto actualizado com sucesso!", {
              theme: "bubble",
              position: "top-center",
              duration: 4000
            });
            return router.push("/home").catch(() => {
              throw new Error("Problem - Contact KP");
            });
          } else {
            Vue.toasted.error(response.data.message, {
              theme: "bubble",
              position: "top-center",
              duration: 4000
            });
          }
        })
        .catch(function() {
          Vue.toasted.error("Ups! Verifique a sua conexão de internet", {
            theme: "bubble",
            position: "top-center",
            duration: 3000
          });
        })
        .finally(function() {
          eventBus.$emit("openOverlay", false);
        });
    },

    updateHospital(context, payload) {
      eventBus.$emit("openOverlay", true);
      context.commit("SET_LOADING_BUTTON", true);
      return apiServices
        .callUpdateHospital(payload.uuid)
        .then(function(response) {
          if (response.status === 200) {
            let obj = response.data;
            if (obj.code === 200 && obj.type === "success") {
              let disease = context.state.allHospitals;
              let ds = disease.map(u => {
                return u.id == payload.item
                  ? { ...u, doenca: payload.doenca }
                  : u;
              });
              console.log(ds);
              context.commit("SET_UPDATE_DISEASE", ds);

              Vue.toasted.success("Doença actualizada", {
                theme: "bubble",
                position: "top-center",
                duration: 4000
              });
            }
          } else {
            Vue.toasted.error(response.data.message, {
              theme: "bubble",
              position: "top-center",
              duration: 4000
            });
          }
        })
        .catch(function() {
          Vue.toasted.error("Ups! Verifique a sua conexão de internet", {
            theme: "bubble",
            position: "top-center",
            duration: 3000
          });
        })
        .finally(function() {
          eventBus.$emit("openOverlay", false);
        });
    },

    getAllResearch(context, payload) {
      eventBus.$emit("openOverlay", true);
      context.commit("SET_LOADING_BUTTON", true);
      return apiServices
        .callAllResearch(payload)
        .then(function(response) {
          if (response.status === 200) {
            let obj = response.data;
            if (obj.code === 200 && obj.type === "success") {
              context.commit("SET_ALL_RESEARCH", obj.message);
              console.log(obj.message);
            }
          } else {
            Vue.toasted.error(response.data.message, {
              theme: "bubble",
              position: "top-center",
              duration: 4000
            });
          }
        })
        .catch(function() {
          Vue.toasted.error("Ups! Verifique a sua conexão de internet", {
            theme: "bubble",
            position: "top-center",
            duration: 3000
          });
        })
        .finally(function() {
          eventBus.$emit("openOverlay", false);
        });
    },

    isMobile() {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      );
    },

    createInvoice(context, payload) {
      eventBus.$emit("openOverlay", true);
      return apiServices
        .callCreateInvoice(payload)
        .then(function(response) {
          if (response.status === 200) {
            let obj = response.data;
            context.commit("SET_ORDER", obj);
            return new Promise(r => setTimeout(r, 2000)).then(() => {
              window.print();
              //console.log(obj);
              if (!this.isMobile()) {
                Vue.toasted.success("Ordem realizada com sucesso!", {
                  theme: "bubble",
                  position: "top-center",
                  duration: 4000
                });
              }
            });
          } else {
            Vue.toasted.error(response.data.message, {
              theme: "bubble",
              position: "top-center",
              duration: 4000
            });
          }
        })
        .catch(function() {
          Vue.toasted.error("Ups! Verifique a sua conexão de internet", {
            theme: "bubble",
            position: "top-center",
            duration: 3000
          });
        })
        .finally(function() {
          eventBus.$emit("openOverlay", false);
        });
    },

    getEntities(context, payload) {
      eventBus.$emit("openOverlay", true);
      context.commit("SET_LOADING_BUTTON", true);
      return apiServices
        .callEntity(payload)
        .then(function(response) {
          if (response.status === 200) {
            let obj = response.data;
            context.commit("SET_ALL_ENTITIES", obj);
          } else {
            // Vue.toasted.error(response.data.message, {
            //   theme: "bubble",
            //   position: "top-center",
            //   duration: 4000
            // });
          }
        })
        .catch(function() {
          // Vue.toasted.error("Ups! Verifique a sua conexão de internet", {
          //   theme: "bubble",
          //   position: "top-center",
          //   duration: 3000
          // });
        })
        .finally(function() {
          eventBus.$emit("openOverlay", false);
        });
    },

    getRole(context, payload) {
      eventBus.$emit("openOverlay", true);
      // context.commit("SET_LOADING_BUTTON", true);
      return apiServices
        .callGetRole(payload)
        .then(function(response) {
          if (response.status === 200) {
            let obj = response.data;
            context.commit("SET_ALL_ROLES", obj.role);
          } else {
            Vue.toasted.error(response.data.message, {
              theme: "bubble",
              position: "top-center",
              duration: 4000
            });
          }
        })
        .catch(function() {
          // Vue.toasted.error("Ups! Verifique a sua conexão de internet", {
          //   theme: "bubble",
          //   position: "top-center",
          //   duration: 3000
          // });
        })
        .finally(function() {
          eventBus.$emit("openOverlay", false);
        });
    },

    getAllUsers(context, payload) {
      eventBus.$emit("openOverlay", true);
      context.commit("SET_LOADING_BUTTON", true);
      return apiServices
        .callAllUsers(payload)
        .then(function(response) {
          if (response.status === 200) {
            let obj = response.data;
            context.commit("SET_ALL_USERS", obj);
          } else {
            Vue.toasted.error(response.data.message, {
              theme: "bubble",
              position: "top-center",
              duration: 4000
            });
          }
        })
        .catch(function() {
          Vue.toasted.error("Ups! Verifique a sua conexão de internet", {
            theme: "bubble",
            position: "top-center",
            duration: 3000
          });
        })
        .finally(function() {
          eventBus.$emit("openOverlay", false);
        });
    },

    deleteUser(context, payload) {
      eventBus.$emit("openOverlay", true);
      context.commit("SET_LOADING_BUTTON", true);
      return apiServices
        .callDeleteUser(payload)
        .then(function(response) {
          if (response.status === 200) {
            Vue.toasted.success("Utilizador eliminado com sucesso!", {
              theme: "bubble",
              position: "top-center",
              duration: 4000
            });
            return router.push("/home").catch(() => {
              throw new Error("Problem - Contact KP");
            });
          } else {
            Vue.toasted.error(response.data.message, {
              theme: "bubble",
              position: "top-center",
              duration: 4000
            });
          }
        })
        .catch(function() {
          Vue.toasted.error("Ups! Verifique a sua conexão de internet", {
            theme: "bubble",
            position: "top-center",
            duration: 3000
          });
        })
        .finally(function() {
          eventBus.$emit("openOverlay", false);
        });
    },

    //Done
    login(context, payload) {
      eventBus.$emit("openOverlay", true);
      // let users = context.getters.getUserList;
      // let obj = [];
      // let dex = [];
      // users.map((u, index) => {
      //   if (u.email === payload.email && u.pwd === payload.pwd) {
      //     obj.push(2);
      //     dex.push(index);
      //     localStorage.setItem("profile", u.name);
      //   }
      // });

      return apiServices
        .getLogin(payload)
        .then(function(response) {
          if (response.status === 200) {
            let obj = response.data;
            if (obj.session) {
              apiServices.customizeHeader(obj.session);
              localStorage.setItem("token", obj.session);
              Vue.toasted.success("Acesso concedido!", {
                theme: "bubble",
                position: "top-center",
                duration: 2000
              });
              return router.push("/home").catch(() => {
                throw new Error("Problem - Contact KP");
              });
            } else {
              Vue.toasted.error(response.data.message, {
                theme: "bubble",
                position: "top-center",
                duration: 4000
              });
            }
          } else {
            Vue.toasted.error(response.data.message, {
              theme: "bubble",
              position: "top-center",
              duration: 4000
            });
          }
        })
        .catch(function() {
          Vue.toasted.error("Ups! Verifique a sua conexão de internet", {
            theme: "bubble",
            position: "top-center",
            duration: 3000
          });
        })
        .finally(function() {
          eventBus.$emit("openOverlay", false);
        });
    },

    createItem(context, payload) {
      eventBus.$emit("openOverlay", true);
      return apiServices
        .registerProduct(payload)
        .then(function(response) {
          if (response.status === 200) {
            Vue.toasted.success("Item registado com sucesso", {
              theme: "bubble",
              position: "top-center",
              duration: 2000
            });
            return router.push("/home").catch(() => {
              throw new Error("Problem - Contact Delcio Cabanga");
            });
          } else {
            Vue.toasted.error(response.data.message, {
              theme: "bubble",
              position: "top-center",
              duration: 4000
            });
          }
        })
        .catch(function() {
          Vue.toasted.error("Ups! Verifique a sua conexão de internet", {
            theme: "bubble",
            position: "top-center",
            duration: 3000
          });
        })
        .finally(function() {
          eventBus.$emit("openOverlay", false);
        });
    },

    bulk(context, payload) {
      eventBus.$emit("openOverlay", true);
      return apiServices
        .callCreateBulkReference(payload)
        .then(function(response) {
          if (response.status === 200) {
            Vue.toasted.success("A gerar referências em massa!", {
              theme: "bubble",
              position: "top-center",
              duration: 2000
            });

            const blob = new Blob([response.data]);
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            const d = new Date();
            let year =
              d.getFullYear() +
              "_" +
              d.getMonth() +
              "_" +
              d.getDay() +
              "_" +
              d.getSeconds();
            const nameReport = "BulkReference_Kpay_" + year + ".csv";
            link.setAttribute("download", nameReport);
            document.body.appendChild(link);
            link.click();

            return true;
          } else {
            Vue.toasted.error("Não foi gerada nenhuma referência", {
              theme: "bubble",
              position: "top-center",
              duration: 4000
            });
          }
        })
        .catch(function() {
          Vue.toasted.error("Ups! Verifique a sua conexão de internet", {
            theme: "bubble",
            position: "top-center",
            duration: 3000
          });
        })
        .finally(function() {
          eventBus.$emit("openOverlay", false);
        });
    },

    genReference(context, payload) {
      eventBus.$emit("openOverlay", true);
      return apiServices
        .callCreateReference(payload)
        .then(function(response) {
          if (response.status === 200) {
            let obj = response.data;
            const oj = {
              reference: obj.reference,
              price: obj.price,
              entity: obj.entity,
              expiry: obj.expiry
            };

            Vue.toasted.success("Referência Gerada com sucesso!", {
              theme: "bubble",
              position: "top-center",
              duration: 2000
            });

            context.commit("SET_REFERENCE_IN_STORE", oj);

            return router.push("/reference/accept").catch(() => {
              throw new Error("Problem - Contact KPAY");
            });
          } else {
            Vue.toasted.error("Não foi gerada nenhuma referência", {
              theme: "bubble",
              position: "top-center",
              duration: 4000
            });
          }
        })
        .catch(function() {
          Vue.toasted.error("Ups! Verifique a sua conexão de internet", {
            theme: "bubble",
            position: "top-center",
            duration: 3000
          });
        })
        .finally(function() {
          eventBus.$emit("openOverlay", false);
        });
    },

    createUser(context, payload) {
      eventBus.$emit("openOverlay", true);
      return apiServices
        .callCreateUser(payload)
        .then(function(response) {
          if (response.status === 200) {
            Vue.toasted.success("Utilizador registado com sucesso", {
              theme: "bubble",
              position: "top-center",
              duration: 2000
            });
            return router.push("/home").catch(() => {
              throw new Error("Problem - Contact ");
            });
          } else {
            Vue.toasted.error(response.data.message, {
              theme: "bubble",
              position: "top-center",
              duration: 4000
            });
          }
        })
        .catch(function() {
          Vue.toasted.error("Ups! Verifique a sua conexão de internet", {
            theme: "bubble",
            position: "top-center",
            duration: 3000
          });
        })
        .finally(function() {
          eventBus.$emit("openOverlay", false);
        });
    },

    createEntity(context, payload) {
      eventBus.$emit("openOverlay", true);
      return apiServices
        .callCreateEntity(payload)
        .then(function(response) {
          if (response.status === 200) {
            Vue.toasted.success("Entidade registada com sucesso", {
              theme: "bubble",
              position: "top-center",
              duration: 2000
            });
            return router.push("/home").catch(() => {
              throw new Error("Problem - Contact KPAY");
            });
          } else {
            Vue.toasted.error(response.data.message, {
              theme: "bubble",
              position: "top-center",
              duration: 4000
            });
          }
        })
        .catch(function() {
          Vue.toasted.error("Ups! Verifique a sua conexão de internet", {
            theme: "bubble",
            position: "top-center",
            duration: 3000
          });
        })
        .finally(function() {
          eventBus.$emit("openOverlay", false);
        });
    },

    confirmPayment(context, payload) {
      eventBus.$emit("openOverlay", true);
      return apiServices
        .consultReference(payload)
        .then(function(response) {
          if (response.status === 200) {
            let payload = {
              dialog: true,
              info: "Esta referência foi paga com sucesso!",
              validate: true
            };
            eventBus.$emit("openDialogConfirmPay", payload);
          } else {
            let payload = {
              dialog: true,
              info: "Esta referência não foi processada",
              validate: false
            };
            eventBus.$emit("openDialogConfirmPay", payload);
          }
        })
        .catch(function() {
          Vue.toasted.error("Ups! Verifique a sua conexão de internet", {
            theme: "bubble",
            position: "top-center",
            duration: 3000
          });
        })
        .finally(function() {
          eventBus.$emit("openOverlay", false);
        });
    },

    listSicks(context) {
      eventBus.$emit("openOverlay", true);
      return apiServices
        .listAllSicks()
        .then(function(response) {
          if (response.status === 200) {
            let obj = response.data;
            if (obj.code === 200 && obj.type === "success") {
              context.commit("SET_ALL_SICK", obj.message);
            }
          } else {
            Vue.toasted.error(response.data.message, {
              theme: "bubble",
              position: "top-center",
              duration: 4000
            });
          }
        })
        .catch(function() {
          Vue.toasted.error("Ups! Verifique a sua conexão de internet", {
            theme: "bubble",
            position: "top-center",
            duration: 3000
          });
        })
        .finally(function() {
          eventBus.$emit("openOverlay", false);
        });
    }
  },
  modules: {},
  getters: {
    getProducts(state) {
      return state.products;
    },
    getItemInStore(state) {
      return state.store;
    },
    getTotalGlobal(state) {
      return state.saveTotalGlobal;
    },

    getOrder(state) {
      if (state.order) {
        return state.order;
      }
      return false;
    },
    getOperator(state) {
      return state.operator;
    },

    getReference(state) {
      return state.reference;
    },

    getRoles(state) {
      return state.roles;
    },

    getEntities(state) {
      return state.entities;
    },

    getListUsers(state) {
      return state.listUsers;
    },
    getEditItemList(state) {
      return state.editItem;
    },

    getOptReference(state) {
      let text = "Caríssimo, para efectuar o seu pagamento, use a referência";
      let ref =
        "Entidade: " +
        state.reference.entity +
        "\nReferencia: " +
        state.reference.reference +
        "\nMontante: " +
        state.reference.price +
        " AKz";
      return text + "\n" + ref;
    }
  }
});
