import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/app/Home";

import PageReport from "../views/report/PageReport";
import Login from "../views/login/Login";
import Employer from "../components/home/Employer";
import CreateItem from "../components/home/CreateItem";
import ConfirmPayment from "../components/home/ConfirmPayment";
import GenReference from "../components/home/GenReference";
import AcceptReference from "../components/home/AcceptReference";
import Config from "../components/home/Config";

import store from "./../store/modules/access/index";
import ListUser from "../components/home/ListUser";
import ListProduct from "../components/home/ListProduct";
import Bulk from "../components/home/Bulk";
import EditItem from "../components/home/EditItem";
// import apiServices from "../../api/apiServices";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Login",
    component: Login,
    meta: {
      title: "GR REFERENCE"
    }
  },
  {
    path: "/home",
    name: "home",
    component: Home,
    beforeEnter: (to, from, next) => {
      const userToken = localStorage.getItem("token");
      if (userToken) {
        return next();
      }
      next({
        name: "Login"
      });
    },
    meta: {
      title: "",
      requiresAuth: true
    },
    children: [
      {
        path: "/create/user",
        name: "create.user",
        component: Employer,
        beforeEnter: (to, from, next) => {
          const userToken = localStorage.getItem("token");
          if (userToken) {
            return next();
          }
          next({
            name: "Login"
          });
        },
        meta: {
          title: "",
          requiresAuth: true
        }
        // props: true,
      },

      {
        path: "/config",
        name: "config",
        component: Config,
        beforeEnter: (to, from, next) => {
          const userToken = localStorage.getItem("token");
          let role = store.getters.getRoles;
          if (userToken) {
            if (role === "root") return next();
          }
          next({
            name: "Login"
          });
        },
        meta: {
          title: "",
          requiresAuth: true
        }
        // props: true,
      },

      {
        path: "/payment",
        name: "payment",
        component: PageReport,
        beforeEnter: (to, from, next) => {
          const userToken = localStorage.getItem("token");
          if (userToken) {
            next();
          }
          next(false);
        },
        meta: {
          title: "",
          requiresAuth: true
        }
        // props: true,
      },

      {
        path: "/generate/ref/express",
        name: "express",
        component: GenReference,
        beforeEnter: (to, from, next) => {
          const userToken = localStorage.getItem("token");
          if (userToken) {
            next();
          }
          next(false);
        },
        meta: {
          title: "",
          requiresAuth: true
        }
        // props: true,
      },

      {
        path: "/generate/ref/bulk",
        name: "bulk",
        component: Bulk,
        beforeEnter: (to, from, next) => {
          const userToken = localStorage.getItem("token");
          if (userToken) {
            next();
          }
          next(false);
        },
        meta: {
          title: "",
          requiresAuth: true
        }
        // props: true,
      },

      {
        path: "/reference/accept",
        name: "ref.accept",
        component: AcceptReference,
        beforeEnter: (to, from, next) => {
          const userToken = localStorage.getItem("token");
          if (userToken) {
            next();
          }
          next(false);
        },
        meta: {
          title: "",
          requiresAuth: true
        }
        // props: true,
      },

      {
        path: "/list/user",
        name: "list.user",
        component: ListUser,
        beforeEnter: (to, from, next) => {
          const userToken = localStorage.getItem("token");
          if (userToken) {
            next();
          }
          next(false);
        },
        meta: {
          title: "",
          requiresAuth: true
        }
        // props: true,
      },

      {
        path: "/list/product",
        name: "list.product",
        component: ListProduct,
        beforeEnter: (to, from, next) => {
          const userToken = localStorage.getItem("token");
          if (userToken) {
            next();
          }
          next(false);
        },
        meta: {
          title: "",
          requiresAuth: true
        }
        // props: true,
      },

      {
        path: "/edit/product",
        name: "edit.product",
        component: EditItem,
        beforeEnter: (to, from, next) => {
          const userToken = localStorage.getItem("token");
          if (userToken) {
            next();
          }
          next(false);
        },
        meta: {
          title: "",
          requiresAuth: true
        }
        // props: true,
      },

      {
        path: "/create/product",
        name: "create.product",
        component: CreateItem,
        beforeEnter: (to, from, next) => {
          const userToken = localStorage.getItem("token");
          if (userToken) {
            return next();
          }
          next({
            name: "Login"
          });
        },
        meta: {
          title: "Criar Produto",
          requiresAuth: true
        }
      },

      {
        path: "/confirm/payment",
        name: "confirm.payment",
        component: ConfirmPayment,
        beforeEnter: (to, from, next) => {
          const userToken = localStorage.getItem("token");
          if (userToken) {
            // store.dispatch("listSicks")
            return next();
          }
          next({
            name: "Login"
          });
        },
        meta: {
          title: "",
          requiresAuth: true
        }
        // props: true,
      }
    ]
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  const logged = localStorage.getItem("token");
  if (
    to.matched.some(record => record.meta.requiresAuth) &&
    !logged &&
    to.name !== "Login"
  ) {
    next({
      name: "Login"
    });
  } else {
    if (logged) {
      next();
    } else {
      next();
    }
  }
});

router.afterEach(to => {
  Vue.nextTick(() => {
    document.title = to.meta.title || "GF KPAY";
  });
});

export default router;
