<template>
  <div>
    <v-card elevation="0" width="600" class="mx-auto m-txt-center mt-12">
      <v-card-text class="text-uppercase mt-12 m-size-font-14 font-weight-bold"
        >Editar Produto</v-card-text
      >
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-text-field
          v-model="getEditItemList.name"
          :rules="nameRules"
          id="name"
          hint="Digite campo é obrigatório"
          label="Escreva o nome do Item"
        ></v-text-field>
        <v-text-field
          v-model="getEditItemList.price"
          hint="Este campo é obrigatório"
          label="Digite o preço"
          @input="formatCurrency"
          id="price"
        ></v-text-field>
        <v-text-field
          v-model="getEditItemList.iva"
          :rules="ivaRules"
          hint="Este campo é obrigatório"
          label="Digite a percentagem do IVA (Ex: 14)"
          id="iva"
        ></v-text-field>

        <v-text-field
            type="hidden"
            v-model="getEditItemList.id"
            :rules="ivaRules"
            id="id"
        ></v-text-field>
      </v-form>
      <div class="mt-6 text-left">
        <v-btn @click="editItem" depressed color="primary"> Guardar </v-btn>
        <v-btn :to="{ name: 'home' }" depressed color="success" class="mx-2">
          Voltar
        </v-btn>
      </div>
    </v-card>
    <overlay></overlay>
  </div>
</template>

<script>
import { mapHelper } from "@/store/helper";
import overlay from "@/components/helpers/overlay";
import eventBus from "../../main";
export default {
  name: "EditItem",
  components: { overlay },
  data: () => ({
    name: "",
    valid: false,
    price: "",
    iva: "0",
    id: "",
    txtRules: [
      v => v.length <= 60 || "Máximo 60 caracteres",
      v => !!v || "Campo obrigatório",
      v => /^[0-9,.]*$/.test(v) || "Deve ser valor monetário"
    ],

    nameRules: [
      v => v.length <= 60 || "Máximo 60 caracteres",
      v => !!v || "Campo obrigatório"
    ],

    ivaRules: [
      v => v.length <= 60 || "Máximo 60 caracteres",
      v => !!v || "Campo obrigatório",
      v => /^-?\d+(\.\d+)?$/.test(v) || "Deve ser número"
    ]
  }),
  methods: {
    formatCurrency() {
      // Remove all non-numeric and non-decimal characters
      let formattedValue = this.price.replace(/[^0-9.]/g, "");

      // Separate decimals by a dot and remove leading zeros
      const parts = formattedValue.split(".");
      if (parts.length > 1) {
        parts[0] = parts[0].replace(/^0+/, "");
        formattedValue = parts.join(".");
      }

      // Add a comma for thousands separation
      const regex = /(\d)(?=(\d{3})+(?!\d))/g;
      formattedValue = formattedValue.replace(regex, "$1,");

      // Update the v-model to display the formatted value
      this.price = formattedValue;
    },

    editItem() {


      let price = document.getElementById("price");
      let name = document.getElementById("name");
      let id = document.getElementById("id");
      let iva = document.getElementById("iva");
      if (price.value === "" || price.value < 1) {
        return false;
      }
      if (name.value === "" || id.value === "" || id.iva === "") {
        return false;
      }

      // if (/^(\-|\+)?([0-9]+(\.[0-9]+)?|Infinity)$/.test(price.value)){
      //
      // }

      const ReformattedValue = price.value.replace(/[^0-9.]/g, "");
      const payload = {
        price: ReformattedValue,
        iva: iva.value,
        name: name.value,
        refId: id.value,
        id_service: 1,
        qtd: "1",
      };
      this.$store.dispatch("editProduct", payload);
      
      // if (this.$refs.form.validate()) {
      //   const payload = {
      //     price: ReformattedValue,
      //     iva: this.iva,
      //     name: this.name,
      //     refId: this.id
      //     // id_service: 1,
      //     // qtd: "1",
      //   };
      //   this.$store.dispatch("editProduct", payload);
      // }
    }
  },

  computed: {
    ...mapHelper,
    // txtRules() {
    //   //      return [this.$v.price.v, this.$v.price.v, this.$v.price.v];
    //   return [this.$v.price.v];
    // }
  },
  //
  mounted() {
    const self = this;
    eventBus.$on("receiptItemEdit", function(event) {
      //alert(JSON.stringify(event))
      self.name = event.name;
      self.price = event.price;
      self.iva = event.iva;
      self.id = event.id;
    });
  }
};
</script>

<style scoped></style>
