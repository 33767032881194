<template>
  <div>
    <router-view />
  </div>
</template>

<script>

export default {
  name: "IndexVue",
};
</script>

<style scoped></style>
