import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store/modules/access";
import vuetify from "./plugins/vuetify";
import Vuelidate from "vuelidate";
import "@/assets/css/modularCss.css";
// import "@/assets/css/all.css";
import Toasted from "vue-toasted";
import apiServices from "../api/apiServices";
import VueSocialSharing from "vue-social-sharing";

Vue.config.productionTip = false;
Vue.use(Toasted);
Vue.use(Vuelidate);
Vue.use(VueSocialSharing);

const eventBus = new Vue();
export default eventBus;
new Vue({
  router,
  vuetify,
  store,
  created() {
    const userToken = localStorage.getItem("token");
    apiServices.customizeHeader(userToken);
    apiServices.interceptionRoute();
    store.dispatch("getRole").then(() => {
      store.dispatch("getEntities").then(() => {});
    });
  },
  render: h => h(App)
}).$mount("#app");
